import AllisonH from '../gatherImages/Allison_H.png'
import AngelT from '../gatherImages/Angel_T.png'
import BrianF from '../gatherImages/Brian_F.png'
import CatherineS from '../gatherImages/Catherine_S.png'
import ChrisD from '../gatherImages/Chris_D.png'
import ChrisP from '../gatherImages/Chris_P.png'
import CodyR from '../gatherImages/Cody_R.png'
import HenryK from '../gatherImages/Henry_K.png'
import IanH from '../gatherImages/Ian_H.png'
import JohnD from '../gatherImages/John_D_Rat.png'
import JoyG from '../gatherImages/Joy_G.png'
import JustinD from '../gatherImages/Justin_D.png'
import KevinS from '../gatherImages/Kevin_S.png'
import KristineM from '../gatherImages/Kristine_M.png'
import MichaelG from '../gatherImages/Michael_G.png'
import MoussaS from '../gatherImages/Moussa_S.png'
import NatalieJ from '../gatherImages/Natalie_J.png'
import ThaddeusW from '../gatherImages/Thaddeus_W.png'
import ZachA from '../gatherImages/Zach_A.png'
import EdisonA from '../gatherImages/Edison_A.png'
import MylesY from '../gatherImages/Myles_Y.png'
import MichaelT from '../gatherImages/Michael_T.png'
import NathanielW from '../gatherImages/Nathaniel_W.png'
import ConlethS from '../gatherImages/Conleth_S.png'
import AdityaS from '../gatherImages/Aditya_S.png'
import MartaS from '../gatherImages/Marta_S.png'
import KingsleyO from '../gatherImages/Kingsley_O.png'
import JonS from '../gatherImages/Jon_S.png'
import JaiM from '../gatherImages/Jariatu_M.png'
import AhmedS from '../gatherImages/Ahmed_S.png'
import KellyL from '../gatherImages/Kelly_L.png'
import AshleyJ from '../gatherImages/Ashley_J.png'
import KatieC from '../gatherImages/Katie_C.png'
import JennaP from '../gatherImages/Jenna_P.png'

const SOFTWARE_ENGINEER = 'Software Engineer'
const PRODUCT_DESIGNER = 'Product Designer'
const PRODUCT_MANAGER = 'Product Manager'
const SOFTWARE_COACH = 'Software Coach'
const CLIENT_LIAISON = 'Client Liaison'
export const teamMembers = [
    {
        name: 'Allison Hanna',
        role: SOFTWARE_ENGINEER,
        image: AllisonH,
    },
    {
        name: 'Angel Tsai',
        role: PRODUCT_DESIGNER,
        image: AngelT,
    },
    {
        name: 'Brian Frazho',
        role: SOFTWARE_ENGINEER,
        image: BrianF,
    },
    {
        name: 'Catherine Steigerwald',
        role: SOFTWARE_ENGINEER,
        image: CatherineS,
    },
    {
        name: 'Chris Dorman',
        role: SOFTWARE_ENGINEER,
        image: ChrisD,
    },
    {
        name: 'Chris Plawecki',
        role: SOFTWARE_ENGINEER,
        image: ChrisP,
    },
    {
        name: 'Henry Kondratko',
        role: SOFTWARE_ENGINEER,
        image: HenryK,
    },
    {
        name: 'John Daybell',
        role: SOFTWARE_ENGINEER,
        image: JohnD,
    },
    {
        name: 'Zach Aufdemberge',
        role: SOFTWARE_ENGINEER,
        image: ZachA,
    },
    {
        name: 'Thaddeus Warner',
        role: SOFTWARE_ENGINEER,
        image: ThaddeusW,
    },
    {
        name: 'Natalie Jones',
        role: SOFTWARE_ENGINEER,
        image: NatalieJ,
    },
    {
        name: 'Moussa Shishani',
        role: SOFTWARE_ENGINEER,
        image: MoussaS,
    },
    {
        name: 'Mike Gutowski',
        role: SOFTWARE_ENGINEER,
        image: MichaelG,
    },
    {
        name: 'Kristine Miller',
        role: SOFTWARE_ENGINEER,
        image: KristineM,
    },
    {
        name: 'Kevin Sivic',
        role: SOFTWARE_COACH,
        image: KevinS,
    },
    {
        name: 'Justin Dvorak',
        role: SOFTWARE_ENGINEER,
        image: JustinD,
    },
    {
        name: 'Joy Gope',
        role: PRODUCT_MANAGER,
        image: JoyG,
    },
    {
        name: 'Ian Hollander',
        role: SOFTWARE_ENGINEER,
        image: IanH,
    },
    {
        name: 'Cody Rash',
        role: SOFTWARE_ENGINEER,
        image: CodyR,
    },
    {
        name: 'Edison Aviles',
        role: SOFTWARE_ENGINEER,
        image: EdisonA,
    },
    {
        name: 'Myles Yankie',
        role: SOFTWARE_ENGINEER,
        image: MylesY,
    },
    {
        name: 'Michael Tocco',
        role: SOFTWARE_ENGINEER,
        image: MichaelT,
    },
    {
        name: 'Nathan Worrel',
        role: SOFTWARE_ENGINEER,
        image: NathanielW,
    },
    {
        name: 'Conleth Stead',
        role: SOFTWARE_ENGINEER,
        image: ConlethS,
    },
    {
        name: 'Aditya Sinha',
        role: SOFTWARE_ENGINEER,
        image: AdityaS,
    },
    {
        name: 'Marta Szkiladz',
        role: PRODUCT_DESIGNER,
        image: MartaS,
    },
    {
        name: 'Kingsley Ogbonna',
        role: PRODUCT_MANAGER,
        image: KingsleyO,
    },
    {
        name: 'Jon Stoddart',
        role: SOFTWARE_ENGINEER,
        image: JonS,
    },
    {
        name: 'Ahmed Saeed',
        role: SOFTWARE_ENGINEER,
        image: AhmedS,
    },
    {
        name: 'Jai Mansaray',
        role: SOFTWARE_COACH,
        image: JaiM,
    },
    {
        name: 'Kelly Laughter',
        role: PRODUCT_DESIGNER,
        image: KellyL,
    },
    {
        name: 'Ashley Johnson',
        role: SOFTWARE_COACH,
        image: AshleyJ,
    },
    {
        name: 'Katie Cox',
        role: SOFTWARE_ENGINEER,
        image: KatieC,
    },
    {
        name: 'Jenna Parks',
        role: CLIENT_LIAISON,
        image: JennaP,
    },
]

import React from 'react'
import '../styles/global.scss'
import '../styles/globalheader.scss'
import logo from '../images/SA_logo_NoWords.png'

export default function GlobalNav() {
    return (
        <nav id="globalnav">
            <div className="globalnav-content">
                <ul id="globalnav-list" className="globalnav-list">
                    <li className="globalnav-item-logo">
                        <a href="/" aria-label="Software-Artisan-Logo" className="globalnav-link globalnav-link-logo">
                            <img className={'globalnav-image'} src={logo} alt={'Digital Book'}></img>
                        </a>
                    </li>
                    <li>
                        <a href="/" aria-label="Home" className="globalnav-link">
                            <span className="">Home</span>
                        </a>
                    </li>
                    <li>
                        <a href="/about" aria-label="About Us" className="globalnav-link">
                            <span className="">About Us</span>
                        </a>
                    </li>
                </ul>
                <div className={'greenLine nav-line'} />
            </div>
        </nav>
    )
}

import React from 'react'
import Grid from '@mui/material/Grid'
import '../styles/team-shelby.scss'
import aboutBanner from '../static/About-Us-Banner_crop.png'
import teamShelbyLogo from '../images/SuperSnake.png'
import GlobalNav from '../Components/GlobalNav'

export default function teamShelby() {
    return (
        <div>
            <div role="navigation">
                <GlobalNav />
            </div>
            <div role="main">
                <Grid container>
                    <Grid item xs={12}>
                        <title>Software Excellence Team - Team Shelby</title>
                        <div>
                            <div className={'greenLine'}/>
                            <div className={'header-container'}>
                                <img className={'banner'} src={aboutBanner} alt={''}/>
                                <h1 className={'teamShelbyHeader'}>Team Shelby</h1>
                            </div>
                            <div className={'greenLine'}/>
                        </div>

                        <div className={'teamShelbyBlurbContainer'}>
                            <img className={'topImage'} src={teamShelbyLogo} alt="" />

                            <div className={'columnFormat'}>
                                <h2>
                                    Our Goal isn't Just to Hand Off Products, <br />
                                    it's to Hand Off Product Teams
                                </h2>
                                <p className="teamShelbyBlurb">
                                    Team Shelby helps provide real solutions to real Ford problems through rapid
                                    prototyping and experimentation to produce fully fleshed out MVPs for stakeholders.
                                    In addition to incubating products, in the best cases Shelby incubates talent to
                                    build high performing teams ready to roll off the line to join your organization and
                                    continue to support their products.
                                </p>

                                <h3>The reason why you might approach us</h3>
                                <ul className="teamShelbyList">
                                    <li>Are you open to a team that works in A New Way to #deliverfaster?</li>
                                    <li>Do you have an OKR you'll struggle to meet?</li>
                                    <li>Do you want to improve your code quality?</li>
                                    <li>Having trouble hiring engineers?</li>
                                    <li>Want to deliver sooner?</li>
                                    <li>Are you meeting your milestones?</li>
                                </ul>

                                <h3>Rapid Replenishment Successes</h3>
                                <ul className="teamShelbyList">
                                    <li>
                                        Iterated to build functionality we didn't even know we needed [at the beginning]
                                    </li>
                                    <li>Postponed functionality that wasn't needed in early releases</li>
                                    <li>Work as whole PDO teams to find lean solutions we can deliver fast</li>
                                    <li>Deliver high quality code, high quality design</li>
                                </ul>
                            </div>
                            <div className={'columnFormat'}>
                                <strong>For more information contact: Chris Dorman</strong>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

import React from 'react'
import '../styles/about.scss'
import culture from '../images/culture-whoo.png'
import pairProgramming from '../images/pair-programming.png'
import cleanCode from '../images/clean-code.png'
import { teamMembers } from '../assets/team-member-data.js'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import aboutBanner from '../static/About-Us-Banner_crop.png'
import GlobalNav from '../Components/GlobalNav'

export default function about() {
    return (
        <div>
            <div role="navigation">
                <GlobalNav />
            </div>
            <div role="main">
                <Grid container>
                    <Grid item xs={12}>
                        <title>Software Excellence Team - About Us</title>
                        <div>
                            <div className={'greenLine'} />
                            <div className={'header-container'}>
                                <img className={'banner'} src={aboutBanner} alt={''} />
                                <h1 className={'aboutHeader'}>About Us</h1>
                            </div>
                            <div className={'greenLine'} />
                        </div>
                        <div className={'aboutUsContainer'}>
                            <img className={'topImage'} src={culture} alt="" />

                            <div className={'columnFormat'}>
                                <h2>Culture</h2>
                                <p>
                                    We focus on creating a safe, learning-focused environment for our team and those who
                                    join us through taking our trainings. We strive to create an environment where we
                                    can be ourselves and grow every day.
                                </p>
                            </div>
                        </div>

                        <div className={'aboutUsContainer'}>
                            <img className={'topImage'} src={pairProgramming} alt="" />

                            <div className={'columnFormat'}>
                                <h2>What We Do</h2>
                                <p>
                                    We focus on learning and teaching others through app development, the Software
                                    Engineering Technical Training course, and the Revolution program where we bring in
                                    employees with any level of engineering experience to come learn with us for two
                                    weeks.
                                </p>
                            </div>
                        </div>

                        <div className={'aboutUsContainer columnFormat'}>
                            <img className={'topImage'} src={cleanCode} alt="" />

                            <div className={'columnFormat'}>
                                <h2>How We Work</h2>
                                <p>
                                    We use clean coding best practices, including TDD and clean code, and provide you
                                    with experience in full-stack web development with the newest tools and
                                    technologies, including CI/CD, Angular/React/Vue, Java/Kotlin, SQL, Jenkins, and
                                    PCF.
                                </p>
                            </div>
                        </div>

                        <div className={'green-about-divider'} />

                        <h2 className={'meet-the-team-header'}>Meet the Team</h2>
                        <Grid container columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                            {teamMembers
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((person, index) => (
                                    <Grid item xs={12} sm={6} md={6} lg={3} xl={2} key={index}>
                                        <ul className={'team-list'}>
                                            <ListItem className={'personCard'}>
                                                <img
                                                    src={person.image}
                                                    className={'personImage'}
                                                    alt="team member"
                                                ></img>
                                                <div className={'personName'}>{person.name}</div>
                                                <div className={'personRole'}>{person.role}</div>
                                            </ListItem>
                                        </ul>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

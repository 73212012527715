import GlobalNav from '../Components/GlobalNav'
import aboutBanner from '../static/About-Us-Banner_crop.png'
import pdQuote from '../images/product-designer-quote.svg'
import seQuote from '../images/software-engineer-quote.svg'
import pmQuote from '../images/product-manager-quote.svg'
import '../styles/fcg.scss'
import React from 'react'

export default function fcg() {
    const EMAIL_RECIPIENTS =
        'mszkila1@ford.com, atsai7@ford.com, jgope@ford.com, cplaweck@ford.com, twarne25@ford.com, kcox51@ford.com'

    const SHADOW_EMAIL_SUBJECT = 'Prospective FCG Shadow'
    const SHADOW_EMAIL_BODY = 'Hi! I am a prospective FCG interested in shadowing a member of the Shelby team.'

    const TOUR_EMAIL_SUBJECT = 'Virtual Office Tour'
    const TOUR_EMAIL_BODY = 'Hi! I am a prospective FCG interested in a tour of your virtual office, Gather.'

    return (
        <div className={'newStyling'}>
            <div role="navigation">
                <GlobalNav />
            </div>
            <main role="main">
                <title>Software Excellence Rotation Program</title>
                <div>
                    <div className={'greenLine'} />
                    <div className={'header-container'}>
                        <img className={'header-image'} src={aboutBanner} alt={''} />
                        <h1 className={'centered'}>Software Excellence Rotation Program</h1>
                    </div>
                    <div className={'greenLine'} />
                </div>
                <section className={'fcg-body-container'}>
                    <div>
                        <h2 className="fcg-header">What is the Software Excellence Rotation Program?</h2>
                        <p>
                            This is a 3-year opportunity on the Software Excellence Team offering Ford College Graduates
                            (FCGs) the freedom to rotate roles (eg. Product Designer, Software Engineer, Product
                            Manager) and projects at any time. Managers will work with you to ensure your experience is
                            tailored to your individual goals and interests (focus on coding, explore design, shadow a
                            product manager, etc.). Our goal is to open doors for you to experience all aspects of
                            product teams while exploring all projects, products and opportunities available within
                            Marketing Sales and Services (MS&S). You’ll have a robust, flexible experience building real
                            products and solving business problems with your tech prowess.
                        </p>
                        <div>
                            <h3 className="fcg-header">Perks:</h3>
                            <ul className="fcg-list">
                                <li>Work from a virtual office alongside other FCGs</li>
                                <li>Option to be fully remote for entirety of 3-year program</li>
                                <li>Gain exposure to a variety of products and teams at Ford</li>
                                <li>
                                    Experience a collaborative culture where learning and personal development are
                                    emphasized and valued
                                </li>
                                <li>Maintain work-life balance</li>
                                <li>
                                    Learn from the best Lean and Agile coaches at Ford who help us facilitate continuous
                                    learning and building fluency in software excellence
                                </li>
                                <li>6-week bootcamp at the start of the rotation to set you up for success</li>
                            </ul>
                        </div>

                        <div>
                            <h3 className={'fcg-header'}>What You'll Get</h3>
                            <p>
                                By the time you leave our rotation program, you’ll be equipped to work on any tech team
                                in Ford using leading software practices.
                                <br />
                                Not convinced after one year? Feel free to rotate to another FCG position. This
                                opportunity is low risk and high reward.
                            </p>
                        </div>

                        <div className={'green-divider'} />

                        <div>
                            <h2 className="fcg-header">What does a typical day look like?</h2>
                            <p>We work between 9am - 4:30pm.</p>
                            <p>
                                We begin each day with a team-wide standup where we discuss what help is needed on the
                                team, present the Tech Tip of the day, and the Computing Woman of the Day (highlighting
                                underrepresented figures in the history of computing).
                            </p>
                            <p>
                                During the week, the individual product teams will meet with stakeholders right after
                                standup. On a weekly basis, we have a designated game time for team bonding, a book club
                                every other week, as well as a meeting with our team coaches for training opportunities
                                and overall learning. On Fridays, the team will run a demo or retro.
                            </p>
                            <p>
                                We promote a healthy work-life balance, prioritizing two 30 minute breaks throughout the
                                day (in addition to lunch break). We also encourage taking time for personal
                                development, observing PowerUp Time every Friday afternoon.
                            </p>
                        </div>

                        <div className={'fcg-button-container'}>
                            <a
                                href={`mailto:${EMAIL_RECIPIENTS}?subject=${SHADOW_EMAIL_SUBJECT}&body=${SHADOW_EMAIL_BODY}`}
                                target="_blank"
                                rel={'noreferrer'}
                            >
                                If you would like to shadow us, click here.
                            </a>
                        </div>

                        <div>
                            <h2 className="fcg-header">What products is the team currently working on?</h2>
                            <p>
                                We partner on internal and end user products such as Ford.com, dealer support, data and
                                analytics (GDI&A), eCommerce, electric vehicle (EV), EV Labs and customer experience.
                            </p>

                            <div className={'flex-container'}>
                                <div className={'flex-child'}>
                                    <h3 className="fcg-header">Current Projects</h3>
                                    <ul>
                                        <li>
                                            <u>Rapid Replenishment:</u> a website used by Ford Dealerships for ordering
                                            vehicles
                                        </li>
                                        <li>
                                            <u>Software Engineering Technical Training:</u> a 1-week course hosted by
                                            our team teaching Ford employees clean code principles
                                        </li>
                                        <li>
                                            <u>Lean Latte:</u> a website used for organizing Lean Coffee meetings
                                        </li>
                                    </ul>
                                </div>

                                <div className={'flex-child'}>
                                    <h3 className="fcg-header">Tech Stacks</h3>
                                    <ul>
                                        <li>Product Design: Figma, UXPin, Adobe Suite, InVision</li>
                                        <li>
                                            Software Engineering: Javascript/Typescript, Java, React, Spring, Kotlin,
                                            NodeJS, Golang, JUnit, and more!
                                        </li>
                                        <li>Product Management: GitHub, Miro, Parrit</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={'green-divider'} />

                        <div>
                            <h2 className="fcg-header">What environments do you work/collaborate in?</h2>
                            <p>
                                We utilize a virtual office (Gather) -
                                <a href="https://www.gather.town/" target="_blank" rel={'noreferrer'}>
                                    https://www.gather.town/
                                </a>
                                . Gather is a set of virtual rooms and spaces designed by our team where we pair/mob,
                                message, hold meetings, collaborate, and socialize. This digital environment helps us
                                mimic in-office relationships and dynamics, promoting spontaneous coworking by simply
                                walking into a shared space and eliminating the need to set-up Webex meetings.
                            </p>
                            <p>We use Miro, POP, and Mural for collaboration and documentation.</p>
                            <p>
                                Click <a href="/about#:~:text=Jenkins%2C%20and%20PCF.-,Meet%20the%20Team">here</a> to
                                meet the team.
                            </p>
                        </div>

                        <div className={'fcg-button-container'}>
                            <a
                                href={`mailto:${EMAIL_RECIPIENTS}?subject=${TOUR_EMAIL_SUBJECT}&body=${TOUR_EMAIL_BODY}`}
                                target="_blank"
                                rel={'noreferrer'}
                            >
                                If you would like to schedule a tour of our virtual office, click here.
                            </a>
                        </div>

                        <div>
                            <h2 className="fcg-header">What do different roles look like?</h2>
                            <ul className="fcg-list">
                                <li>
                                    Everyone on the team has agency to select which products they work on, we offer
                                    rotation opportunities often, generally monthly
                                </li>
                                <li>
                                    Work on an empowered PDO team of software engineers, product designers, and product
                                    managers to build a user focused product by using new ways of working and
                                    contributing to Ford’s institutional knowledge
                                </li>
                                <li>
                                    Practice software excellence when developing from a backlog made by the team with
                                    the product owner and other stakeholders
                                </li>
                                <li>
                                    Imagine, create, and iterate features that solve customers’ problems that align with
                                    targeted business opportunities
                                </li>
                                <li>
                                    This rotation will work closely with the Software Excellence Artisan team daily for
                                    training and guidance
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={'flex-container'}>
                        <div className={'flex-child'}>
                            <img
                                src={pdQuote}
                                alt={
                                    'I support the developers by conducting exploratory user research, synthesizing ' +
                                    'findings, creating personas, user journeys, wireframes, and prototypes as well ' +
                                    'as keeping decisions user-centered.'
                                }
                            />
                        </div>
                        <div className={'flex-child'}>
                            <img
                                src={seQuote}
                                alt={
                                    'I collaborate with product designers and managers to create a backlog of stories' +
                                    ' to create value for all stakeholders of a project. I then create full-stack ' +
                                    'products based off of those stories.'
                                }
                            />
                        </div>
                        <div className={'flex-child'}>
                            <img
                                src={pmQuote}
                                alt={
                                    'I communicate with the Product Owners and the team to define a clear North Star ' +
                                    "to work towards, ensuring that we're building the right product."
                                }
                            />
                        </div>
                    </div>
                </section>
                <div className="fcg-footer-contact-us">
                    <footer>
                        <h3 className="fcg-footer-header">Questions? Please reach out!</h3>
                        <ul className="fcg-footer-list">
                            <li>Product Design - mszkila1@ford.com; atsai7@ford.com</li>
                            <li>Product Management - jgope@ford.com</li>
                            <li>Software Engineering - cplaweck@ford.com; twarne25@ford.com; kcox51@ford.com</li>
                        </ul>
                    </footer>
                </div>
            </main>
        </div>
    )
}

import React from 'react'
import '../styles/homepage.scss'
import angularImage from '../static/angular.png'
import jenkinsImage from '../static/jenkins.png'
import kotlinImage from '../static/kotlin.png'
import javaImage from '../static/java-icon.png'
import springImage from '../static/spring.png'
import vueImage from '../static/vue.png'
import reactImage from '../static/react.png'
import expressImage from '../static/express.png'
import mongodbImage from '../static/mongo.png'
import mysqlImage from '../static/mysql-icon.png'
import cloudFoundryImage from '../static/cloudfoundry-icon.png'
import githubImage from '../static/github.png'
import bookImage from '../static/blackBook.png'
import jobShadowImage from '../static/jobShadowing.png'
import coffeeImage from '../static/coffee.png'
import parritImage from '../static/birds.png'
import rapidReplenishmentImage from '../static/rapid-replenishment.png'
import talentPipelineImage from '../static/talent-pipeline.png'
import dealerSurveyImage from '../static/dealer-survey.png'
import codeTrendz from '../static/code-trendz.png'
import softwareArtisanBackgroundImage from '../static/Home-banner_crop.png'
import shelbyFlagImage from '../images/finish-line.png'

import GlobalNav from '../Components/GlobalNav'

const Homepage = () => {
    return (
        <div className={'newStyling'} role="region">
            <GlobalNav />
            <div>
                <div className={'greenLine'} />
                <div className={'header-container'}>
                    <img
                        className={'homepageBanner'}
                        src={softwareArtisanBackgroundImage}
                        alt={'Software excellence background'}
                    />
                    <div className={'artisanTitleText'}>
                        <h1 data-cy="artisan-title">Software Excellence Team</h1>
                        <p>
                            An inclusive, safe space to learn software engineering.
                            <br />
                            Join us to change the world, one line of code at a time.
                        </p>
                    </div>
                </div>
                <div className={'greenLine'} />
            </div>

            <div className={'programContainer'}>
                <div className={'sectionHeader columnFormat'}>
                    <h2 className={'sectionHeader columnFormat'}>Programs</h2>
                    <p>We offer hands-on learning opportunities!</p>
                </div>
                <div className={'jobShadowingImage columnFormat'}>
                    <img src={jobShadowImage} alt={'Job Shadowing'} />
                </div>
                <h3 className={'jobShadowingTitle columnFormat'}>Job Shadowing</h3>
                <p className={'jobShadowingDescription textColumnFormat'}>
                    Come join our team as part of the Revolution by getting hands-on development experience with our
                    two-week program.
                </p>
                <a className={'jobShadowingButton columnFormat'} href="/revolution" target="_blank">
                    Join the Revolution!
                </a>

                <div className={'teamShelbyImage columnFormat'}>
                    <img src={shelbyFlagImage} alt={'Team Shelby'} />
                </div>
                <h3 className={'teamShelbyTitle columnFormat'}>Team Shelby</h3>
                <p className={'teamShelbyDescription textColumnFormat'}>
                    Developing world-class talent while working on real products.
                </p>
                <a className={'teamShelbyButton columnFormat'} href="/team-shelby">
                    Start a Collaboration!
                </a>

                <div className={'settImage columnFormat'}>
                    <img src={bookImage} alt={'Book and Mouse'} />
                </div>
                <h3 className={'settTitle columnFormat'}>
                    Software Engineering
                    <br />
                    Technical Training
                </h3>
                <p className={'settDescription textColumnFormat'}>
                    An instructor-led course that teaches clean coding practices in Java. The course is conducted using
                    our Journey website.
                </p>
                <a
                    className={'settButton columnFormat'}
                    href="https://journey.ford.com/sett-landing-page"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more and sign up!
                </a>
            </div>

            <div className={'green-about-divider'} />

            <h2 className={'sectionHeader columnFormat'}>Products</h2>
            <p className={'columnFormat'}>Explore Our Portfolio!</p>
            <br></br>
            <div className={'productImageContainer'}>
                <div className="productImageBlock">
                    <img src={coffeeImage} alt={'LeanLatte'} />
                    <div className="overlay">
                        <div className="text">Lean Latte</div>
                    </div>
                </div>
                <div className="productImageBlock">
                    <img src={rapidReplenishmentImage} alt={'Rapid Replenishment'} />
                    <div className="overlay">
                        <div className="text">Rapid Replenishment</div>
                    </div>
                </div>
                <div className="productImageBlock">
                    <img src={dealerSurveyImage} alt={'Dealer Survey'} />
                    <div className="overlay">
                        <div className="text">Dealer Survey</div>
                    </div>
                </div>
                <div className="productImageBlock">
                    <img src={talentPipelineImage} alt={'Talent Pipeline'} />
                    <div className="overlay">
                        <div className="text">Talent Pipeline</div>
                    </div>
                </div>

                <div className="productImageBlock">
                    <img src={parritImage} alt={'Parrit'} />
                    <div className="overlay">
                        <div className="text">Parrit</div>
                    </div>
                </div>
                <div className="productImageBlock">
                    <img src={codeTrendz} alt={'Code Trendz'} />
                    <div className="overlay">
                        <div className="text">Code Trendz</div>
                    </div>
                </div>
            </div>

            <div className={'green-about-divider'} />

            <h2 className={'sectionHeader columnFormat'}>Tech</h2>
            <p className={'textColumnFormat'}>
                We are always working to keep up with and learn the latest technologies.
                <br />
                <br />
                Here are a few we currently use!
            </p>
            <div className={'techImageContainer'}>
                <img className={'techImageColumn'} src={jenkinsImage} alt={'Jenkins'} />
                <img className={'techImageColumn'} src={javaImage} alt={'Java'} />
                <img className={'techImageColumn'} src={kotlinImage} alt={'Kotlin'} />
                <img className={'techImageColumn'} src={springImage} alt={'Spring'} />
                <img className={'techImageColumn'} src={vueImage} alt={'Vue'} />
                <img className={'techImageColumn'} src={angularImage} alt={'Angular'} />
                <img className={'techImageColumn'} src={reactImage} alt={'React'} />
                <img className={'techImageColumn'} src={expressImage} alt={'Express'} />
                <img className={'techImageColumn'} src={mongodbImage} alt={'MongoDB'} />
                <img className={'techImageColumn'} src={mysqlImage} alt={'mySQL'} />
                <img className={'techImageColumn'} src={cloudFoundryImage} alt={'Cloud Foundry'} />
                <img className={'techImageColumn'} src={githubImage} alt={'GitHub'} />
            </div>
        </div>
    )
}

export default Homepage

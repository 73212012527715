import React from 'react'
import './App.css'
import NotFoundPage from './pages/404'
import About from './pages/about'
import Homepage from './pages/homepage.js'
import TeamShelby from './pages/team-shelby'
import Fcg from './pages/fcg'

const { BrowserRouter, Routes, Route } = require('react-router-dom')

function App() {
    return (
        <BrowserRouter forceRefresh={true}>
            <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route path="/about" element={<About />} />
                <Route path="/team-shelby" element={<TeamShelby />} />
                <Route path="/fcg" element={<Fcg />} />
                <Route path="/404" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
